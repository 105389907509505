import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import Section from 'components/atoms/layout/Section'
import BackgroundHeading from 'components/atoms/headings/BackgroundHeading'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import FirstLetter from 'components/atoms/text/FirstLetter'
import MoreButton from 'components/atoms/buttons/MoreButtonLink'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContentGrid = styled(Grid)`
  ${breakpoints.lessThan('md')`
    order: 2;
  `}
`
const StyledImg = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }    
  `}

  ${breakpoints.greaterThan('md')`


    > div {
      padding-bottom: 150% !important;
    }    
  `}

  ${breakpoints.greaterThan('lg')`
    transform: translateY(51px);

    > div {
      padding-bottom: 125% !important;
    }    
  `}
`

const About = ({ home }) => {
  let sectionPadding = theme.sectionPadding
  sectionPadding.xl = '200px'

  return (
    <Section
      pt={sectionPadding}
      pb={{ xs: 4, md: 6, lg: 0 }}
      style={{ zIndex: 100 }}
    >
      <BackgroundHeading>About Us</BackgroundHeading>
      <Container maxWidth="xl" className="foreground">
        <Grid container spacing={3}>
          <ContentGrid item xs={12} md={6} lg={5}>
            <TextPlx reverse>
              <SmallHeading mt={{ lg: 10, xl: 12 }}>
                {home.aboutSectionSmallHeading}
              </SmallHeading>
            </TextPlx>
            <TextPlx reverse>
              <FirstLetter>{home.aboutSectionMainHeading}</FirstLetter>
            </TextPlx>

            <ScrollRevealText
              components={home.aboutSectionTextComponents}
              keyId="home-about"
              mt={2}
              reverse
            />
            <TextPlx reverse>
              <MoreButton
                to="/collections"
                label={home.aboutSectionButtonLabel}
              />
            </TextPlx>
          </ContentGrid>
          <Grid item lg={1} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={6}>
            <StyledImg fluid={home.aboutSectionImage.fluid} />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

About.propTypes = {
  home: PropTypes.object.isRequired,
}

export default memo(About)
