import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'

import Div100vh from 'components/atoms/layout/Div100vh'
import Section from 'components/atoms/layout/Section'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Heading from 'components/atoms/headings/Heading'
import FaderSwiper from 'components/molecules/swipers/FadeSwiper'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import RoundFrame from 'components/atoms/media/RoundFrame'
import SmallText from 'components/atoms/text/SmallText'
import BackgroundPlx from 'components/atoms/plx/BackgroundPlx'
import BackgroundImageContainer from 'components/atoms/media/BackgroundImageContainer'
import TextPlx from 'components/atoms/plx/TextPlx'

const QuoteContainer = styled.div`
  color: white;
  text-align: center;

  .section-heading {
    letter-spacing: 0.4rem;
  }
`

const Quotes = ({ bgImage, quotes, imageHeight }) => {
  const plxBg = [
    {
      start: 'self',
      end: 'self',
      endOffset: '150vh',
      properties: [
        {
          startValue: -150,
          endValue: 80,
          property: 'translateY',
          unit: 'px',
        },
      ],
    },
  ]

  return (
    <Section overflowYHidden overflowXHidden py={5}>
      <BackgroundPlx parallaxData={plxBg} breakpoint="lg">
        <BackgroundImageContainer
          style={{ height: `${imageHeight ? imageHeight : '100'}%` }}
        >
          <Img fluid={bgImage.fluid} />
        </BackgroundImageContainer>
      </BackgroundPlx>
      <Div100vh>
        <Flex full center className="foreground" py={6}>
          <Container maxWidth="lg">
            <TextPlx>
              <FaderSwiper>
                {quotes.map((quote) => {
                  return (
                    <QuoteContainer key={`quote-${quote.node.originalId}`}>
                      <SectionHeading
                        color="white"
                        headingSize="1.5rem"
                        className="caps section-heading"
                      >
                        Happy Clients
                      </SectionHeading>

                      <Heading as="p" headingSize="h3" color="white">
                        &quot;{quote.node.testimonial}&quot;
                      </Heading>
                      {quote.node.personImage && (
                        <RoundFrame mt={2}>
                          <div
                            className="gatsby-image-wrapper"
                            style={{
                              width: '80px',
                              height: '80px',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={quote.node.personImage.fixed.src}
                              width="80"
                              height="80"
                              alt=""
                            />
                          </div>
                        </RoundFrame>
                      )}

                      <Box mt={3}>
                        <SmallText as="footer">
                          {quote.node.personName}
                          {quote.node.personOrganisation
                            ? ` - ${quote.node.personOrganisation}`
                            : ''}
                        </SmallText>
                      </Box>
                    </QuoteContainer>
                  )
                })}
              </FaderSwiper>
            </TextPlx>
          </Container>
        </Flex>
      </Div100vh>
    </Section>
  )
}

Quotes.propTypes = {
  bgImage: PropTypes.object.isRequired,
  imageHeight: PropTypes.number,
  quotes: PropTypes.array.isRequired,
}

export default memo(Quotes)
