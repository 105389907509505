import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Plx from 'react-plx'

import breakpoints from 'helpers/breakpoints'

import Heading from './Heading'

const StyledPlx = styled(Plx)`
  overflow: hidden;
  position: absolute;
  top: -50px;
  left: 0;
`

const BackgroundHeadingBase = styled(({ variant, ...rest }) => (
  <Heading {...rest} />
))`
  ${breakpoints.lessThan('lg')`
    display: none;
  `}

  line-height: 1;
  white-space: nowrap;

  color: ${props =>
    props.variant === 'light'
      ? rgba(255, 255, 255, 0.04)
      : rgba(props.theme.colors.textDefault, 0.04)};
  z-index: 1;
`

const BackgroundHeading = ({ variant, ...props }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '130vh',
      properties: [
        {
          startValue: 150,
          endValue: -50,
          property: 'translateX',
          unit: 'px',
        },
      ],
    },
  ]

  return (
    <StyledPlx parallaxData={parallaxData}>
      <BackgroundHeadingBase
        variant={variant}
        fontFamily="serif"
        headingSize={{ lg: '40rem', xl: '50rem' }}
        {...props}
      />
    </StyledPlx>
  )
}

BackgroundHeading.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
}
export default memo(BackgroundHeading)
