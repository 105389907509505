import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/atoms/buttons/InternalButtonLink'

const MoreButtonLink = ({ to, label, ...props }) => {
  return (
    <Button
      to={to}
      outerLabel={label}
      className="round outer-label content-btn lg-plus"
      mt={4}
      {...props}
    />
  )
}

MoreButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default memo(MoreButtonLink)
