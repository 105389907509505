import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from 'components/atoms/Box'
import SmallText from 'components/atoms/text/SmallText'

const Effect = styled(Box)`
  position: relative;
  background-color: black;
  overflow: hidden;
  cursor: pointer;

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity ${(props) => props.theme.transitions.long};
    transition-delay: 0.3s;
  }
  .blur {
    width: 100%;
    height: 100%;
    transition: filter ${(props) => props.theme.transitions.long};
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      position: absolute;
      top: 16px;
      right: 16px;
      bottom: 16px;
      left: 16px;
      content: '';
      opacity: 0;
      transition: opacity ${(props) => props.theme.transitions.default},
        transform ${(props) => props.theme.transitions.default};
      opacity: 1;
      transform: scale(1);
      transition-delay: 0s;
    }

    &:before {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: scale(0, 1);
    }

    &:after {
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: scale(1, 0);
    }
  }

  .action {
    background-color: ${(props) => props.theme.colors.primary};
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(-50%, -50%, 0) scale(0, 1);
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.default},
      transform ${(props) => props.theme.transitions.default};

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.primary};
    }
  }

  &:hover {
    .inner {
      opacity: 0.2;
      transition-delay: 0s;
    }
    .blur {
      filter: blur(10px);
    }

    .overlay {
      &:before,
      &:after {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.3s;
      }
    }

    .action {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) scale(1, 1);
      transition-delay: 0.3s;
    }
  }
`

const HoverEffect = ({ children, ...props }) => (
  <Effect {...props}>
    <div className="inner">
      <div className="blur">{children}</div>
    </div>
    <div className="overlay">
      <SmallText className="action">View</SmallText>
    </div>
  </Effect>
)

HoverEffect.propTypes = {
  children: PropTypes.object.isRequired,
}

export default memo(HoverEffect)
