import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import breakpoints from 'helpers/breakpoints'

import Div100vh from 'components/atoms/layout/Div100vh'
import Logo from 'images/logo-icon.svg'
import Section from 'components/atoms/layout/Section'

import BackgroundPlx from 'components/atoms/plx/BackgroundPlx'

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.colors.darkBg};
  // height: 150%;

  .gatsby-image-wrapper {
    opacity: 0.5;
    height: 100% !important;
    width: 100% !important;
  }
`

const LogoIcon = styled(Logo)`
  width: 290px;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.15;

  ${breakpoints.greaterThan('lg')`
        width: 390px;
  `}
`

const BackgroundImageLogo = ({ image, children }) => {
  const plxBg = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: -40,
          endValue: 40,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  const plxIcon = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: -15,
          endValue: 15,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <Section overflowYHidden overflowXHidden>
      <Div100vh>
        <BackgroundPlx parallaxData={plxBg} breakpoint="lg">
          <ImageContainer>
            <Img fluid={image.fluid} loading="lazy" />
          </ImageContainer>
        </BackgroundPlx>

        <BackgroundPlx parallaxData={plxIcon} breakpoint="lg">
          <LogoIcon className="logo" />
        </BackgroundPlx>

        {children}
      </Div100vh>
    </Section>
  )
}

BackgroundImageLogo.propTypes = {
  image: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(BackgroundImageLogo)
