import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import SectionWaveBg from 'components/atoms/layout/SectionWaveBg'
import BackgroundHeading from 'components/atoms/headings/BackgroundHeading'
import ScrollbarSwiper from 'components/molecules/swipers/ScrollbarSwiper'
import Heading from 'components/atoms/headings/Heading'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import Box from 'components/atoms/Box'
import InternalLink from 'components/atoms/links/InternalLink'
import HoverEffect from 'components/atoms/media/HoverEffect'
import TextPlx from 'components/atoms/plx/TextPlx'

const Content = styled.div`
  ${breakpoints.lessThan('md')`
    .swiper-slide {
      border-right: 0;
    }
  `}
`

const Card = styled(Box)`
  position: relative;
  text-align: left;
  color: white;
`
const Index = styled.div`
  position: absolute;
  top: 0;
  left: -50px;
  line-height: 1;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1.4rem;
  font-weight: ${props => props.theme.fontWeights.medium};
  letter-spacing: 0.2rem;

  ${breakpoints.lessThan('xl')`
    display: none;
  `}
`

const Collections = ({ home, collections }) => {
  let sectionPaddingTop = { ...theme.sectionPadding }

  sectionPaddingTop.xl = '250px'

  return (
    <SectionWaveBg
      bg="darkBg"
      color="white"
      pb={theme.sectionPadding}
      pt={{ xs: '175px', lg: '210px', xl: '250px' }}
    >
      <BackgroundHeading variant="light">Collections</BackgroundHeading>
      <Content className="foreground">
        <TextPlx>
          <SectionHeading color="white">Collections</SectionHeading>
        </TextPlx>
        <ScrollbarSwiper id="collections">
          {collections.map((collection, index) => {
            const cardIndex = index + 1

            return (
              <div key={`collection-${collection.node.originalId}`}>
                <Card mx={{ xs: 1, md: 2, lg: 8, xl: 10 }}>
                  <Index>
                    {cardIndex < 10 ? '0' + cardIndex.toString() : cardIndex}
                  </Index>
                  <Link to={`/${collection.node.slug}`}>
                    <HoverEffect>
                      <Img
                        fluid={collection.node.heroSectionBackgroundImage.fluid}
                      />
                    </HoverEffect>
                  </Link>
                  <InternalLink to={`/${collection.node.slug}`} primaryHover>
                    <Heading
                      as="h3"
                      headingSize={{
                        xs: '2.8rem',
                        sm: '2.9rem',
                        md: '3.2rem',
                      }}
                      mt={{ xs: 2, lg: 3 }}
                      color="white"
                      textAlign={{ xs: 'center', lg: 'left' }}
                    >
                      {collection.node.collectionName}
                    </Heading>
                  </InternalLink>
                </Card>
              </div>
            )
          })}
        </ScrollbarSwiper>
      </Content>
    </SectionWaveBg>
  )
}

Collections.propTypes = {
  home: PropTypes.object.isRequired,
  collections: PropTypes.array.isRequired,
}

export default memo(Collections)
