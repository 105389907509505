import React, { memo } from 'react'
import styled from 'styled-components'

const StyledVignette = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    0,
    rgba(0, 0, 0, 0.3) -50%,
    rgba(0, 0, 0, 0.5) 100%
  );
`

const Vignette = () => {
  return <StyledVignette />
}

export default memo(Vignette)
