import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Button from 'components/atoms/buttons/Button'

const InternalButtonLink = props => {
  const CollisionLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={props.to} {...props} />
  ))

  return <Button component={CollisionLink} {...props} />
}
InternalButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
}
export default memo(InternalButtonLink)
