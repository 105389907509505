import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'

import Flex from 'components/atoms/Flex'
import Heading from 'components/atoms/headings/Heading'
import BackgroundImageLogo from 'components/organisms/sections/BackgroundImageLogo'
import TextPlx from 'components/atoms/plx/TextPlx'

const Strapline = ({ home }) => {
  return (
    <BackgroundImageLogo image={home.straplineSectionBackgroundImage}>
      <Container
        maxWidth="lg"
        style={{ height: '100%' }}
        className="foreground"
      >
        <Flex full center>
          <TextPlx>
            <Heading headingSize="h1" color="white" textAlign="center">
              {home.straplineSectionStrapline}
            </Heading>
          </TextPlx>
        </Flex>
      </Container>
    </BackgroundImageLogo>
  )
}

Strapline.propTypes = {
  home: PropTypes.object.isRequired,
}

export default memo(Strapline)
