import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Swiper, Scrollbar, Autoplay } from 'swiper/js/swiper.esm.js'
import ReactIdSwiper from '@danteblitz/react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'

import breakpoints from 'helpers/breakpoints'
import breakpointsBase from 'constants/breakpoints'

const Container = styled.div`
  .swiper-container {
    padding: 0 20px;
    ${breakpoints.greaterThan('sm')`
      padding: 0 30px;
    `}
    ${breakpoints.greaterThan('md')`
      padding: 0 10%;
    `}
    ${breakpoints.greaterThan('lg')`
      padding: 0 17%;
    `}
    ${breakpoints.greaterThan('xl')`
      padding: 0 25%;
    `}
  }

  .swiper-nav-wrapper {
    display: flex;
    align-items: center;
    margin: 50px auto 0;
    padding: 0 20px;

    ${breakpoints.greaterThan('xl')`
      max-width: ${props => props.theme.breakpointsPx.xl};
    `}
  }

  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    height: auto;
    border-right: 1px solid rgba(255, 255, 255, 0.14);
    transition: opacity ${props => props.theme.transitions.default};

    &:last-child {
      border-right: none;
    }
  }

  .scrollbar-container {
    max-width: 460px;
    flex-grow: 1;
    height: 2px;
    margin: 0 auto;
    padding-top: 60px;

    .swiper-scrollbar {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.2);

      .swiper-scrollbar-drag {
        height: 3px;
        top: -1px;
        background: white;
      }
    }
  }

  .nav-btn {
    color: ${props => props.theme.colors.textDark};
    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
    }
  }
`

const ScrollbarSwiper = ({ children }) => {
  const params = {
    Swiper,
    modules: [Scrollbar, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    freeMode: true,
    freeModeSticky: true,
    navWrapper: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      // [breakpointsBase.xl]: {
      //   slidesPerView: 2,
      // },
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      dragSize: 'auto',
      hide: false,
    },
    // eslint-disable-next-line react/display-name
    renderScrollbar: () => (
      <div className="scrollbar-container">
        <div className="swiper-scrollbar" />
      </div>
    ),
  }

  return (
    <Container>
      <ReactIdSwiper {...params}>{children}</ReactIdSwiper>
    </Container>
  )
}

ScrollbarSwiper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(ScrollbarSwiper)
