import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import is from 'styled-is'

const StyledLink = styled(({ primaryHover, btnLink, ...rest }) => (
  <Link {...rest} />
))`
  &,
  > * {
    color: ${props => props.theme.colors.primary};

    &,
    .hover-effect {
      transition: color ${props => props.theme.transitions.default};
    }

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  ${is('primaryHover')`
    &,
    > * {
      color: inherit;

      &:hover {
        &,
        .hover-effect {
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  `}

  ${is('buttonLink')`
    font-weight: ${props => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
    font-size: 1.3rem;

    &,
    span,
    svg {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      margin-left: .6rem;

      path {
        transition: fill ${props => props.theme.transitions.default};
      }
    }

      &:hover {
        svg {
          path {
            fill: ${props => props.theme.colors.secondary};
          }
        }
      }

  `}
`

const InternalLink = ({ to, children, ...props }) => {
  return (
    <StyledLink to={to} {...props}>
      {children}
    </StyledLink>
  )
}

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(InternalLink)
