import React, { memo } from 'react'
import styled from 'styled-components'

const Text = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  font-size: 3rem;
  color: ${props => props.theme.colors.textDefault};
  line-height: 1.3;
  &:first-letter {
    display: block;
    float: left;
    font-size: 8.3rem;
    line-height: 1;
    position: relative;

    margin-right: 1rem;
    margin-bottom: -2rem;
  }
`

const FirstLetter = props => <Text {...props} />

export default memo(FirstLetter)
