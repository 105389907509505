import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Section from 'components/atoms/layout/Section'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Div100vh from 'components/atoms/layout/Div100vh'
import BackgroundPlx from 'components/atoms/plx/BackgroundPlx'
import BackgroundVideo from 'components/atoms/media/BackgroundVideo'
import LineBreaks from 'components/atoms/text/LineBreaks'
import Heading from 'components/atoms/headings/Heading'
import HeadingDivider from 'components/atoms/dividers/HeadingDivider'
import FollowButtons from 'components/molecules/social/FollowButtons'
import SmallText from 'components/atoms/text/SmallText'
import InternalButtonLink from 'components/atoms/buttons/InternalButtonLink'
import Explore from 'components/molecules/hero/Explore'

const StyledFlex = styled(Flex)`
  position: relative;
  text-align: center;
  height: 100%;
  color: white;
`

const EndColumn = styled(Box)`
  width: 10%;
  align-self: flex-end;
`

const HomeScreen = ({ mainHeading, btnLink, btnLabel, scrollHeading }) => {
  const parallaxData = [
    {
      start: '0',
      end: '100%',
      properties: [
        {
          startValue: 0,
          endValue: 200,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <Section bg="darkBg">
      <BackgroundPlx parallaxData={parallaxData} breakpoint="lg">
        <BackgroundVideo src="https://beachwood.s3.eu-central-1.amazonaws.com/homescreen-03-1080p-25-3mbps.mp4" />
      </BackgroundPlx>
      <Div100vh>
        <StyledFlex fullWidth>
          <EndColumn />
          <Flex flexDirection="column" full>
            <Flex flexGrow={1} center fullWidth>
              <Box py={4} textAlign="center">
                <Heading as="h1" headingSize="jumbo" color="white">
                  <LineBreaks text={mainHeading} keyId="heroHeading" />
                </Heading>
                <HeadingDivider bg="white" mx="auto" />

                <InternalButtonLink
                  to={`/${btnLink}`}
                  className="xl sharp outline-light"
                  variant="outlined"
                  mt={4}
                >
                  {btnLabel}
                </InternalButtonLink>
              </Box>
            </Flex>

            <Explore>{scrollHeading}</Explore>
          </Flex>
          <EndColumn>
            {/* <Box pl={{ xs: 2, sm: 0 }} pb={{ xs: 2, lg: 4 }} pr={{ xs: 4 }}>
              <FollowButtons variant="light" />
            </Box> */}
          </EndColumn>
        </StyledFlex>
      </Div100vh>
    </Section>
  )
}

HomeScreen.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  scrollHeading: PropTypes.string.isRequired,
  btnLink: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
}

export default memo(HomeScreen)
