import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import Section from 'components/atoms/layout/Section'
import BackgroundHeading from 'components/atoms/headings/BackgroundHeading'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import FirstLetter from 'components/atoms/text/FirstLetter'
import Box from 'components/atoms/Box'
import Button from 'components/atoms/buttons/Button'
import SectionJoin from 'components/atoms/dividers/SectionJoin'
import MoreButton from 'components/atoms/buttons/MoreButtonLink'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContainerGrid = styled(Grid)`
  align-items: center;
`

const ContentGrid = styled(Grid)``

const StyledImg = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }    
  `}

  ${breakpoints.greaterThan('md')`


    > div {
      padding-bottom: 150% !important;
    }    
  `}

  ${breakpoints.greaterThan('lg')`

    > div {
      padding-bottom: 140% !important;
    }    
  `}
`

const Services = ({ home }) => {
  return (
    <Section py={theme.sectionPadding} style={{ zIndex: 100 }}>
      <BackgroundHeading>Services</BackgroundHeading>
      <Container maxWidth="xl" className="foreground">
        <ContainerGrid container spacing={3}>
          <Grid item xs={12} md={6} lg={6} xl={5}>
            <StyledImg fluid={home.servicesSectionImage.fluid} />
          </Grid>
          <Grid
            item
            lg={1}
            xl={2}
            implementation="css"
            mdDown
            component={Hidden}
          />
          <ContentGrid item xs={12} md={6} lg={5} xl={4}>
            <TextPlx>
              <SmallHeading>{home.servicesSectionSmallHeading}</SmallHeading>
            </TextPlx>
            <TextPlx>
              <FirstLetter>{home.servicesSectionMainHeading}</FirstLetter>
            </TextPlx>

            <ScrollRevealText
              components={home.servicesSectionTextComponents}
              keyId="home-services"
              mt={2}
            />

            <TextPlx>
              <MoreButton
                to="/services"
                label={home.servicesSectionButtonLabel}
              />
            </TextPlx>
          </ContentGrid>
        </ContainerGrid>
      </Container>
      <SectionJoin />
    </Section>
  )
}

Services.propTypes = {
  home: PropTypes.object.isRequired,
}

export default memo(Services)
