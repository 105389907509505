import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/organisms/global/Layout'
import HomeScreen from 'components/organisms/_page-specific/homepage/HomeScreen'
import About from 'components/organisms/_page-specific/homepage/About'
import Strapline from 'components/organisms/_page-specific/homepage/Strapline'
import StraplineImage from 'components/organisms/_page-specific/homepage/StraplineImage'
import Collections from 'components/organisms/_page-specific/homepage/Collections'
import Services from 'components/organisms/_page-specific/homepage/Services'
import Quotes from 'components/organisms/sections/Quotes'
import DownloadSection from 'components/organisms/sections/DownloadSection'

const Index = ({ data }) => {
  const home = data.datoCmsHomepage
  const collections = data.allDatoCmsCollection.edges
  const quotes = data.allDatoCmsTestimonial.edges

  const metaObj = {
    title: home.seoMetadata.title,
    description: home.seoMetadata.description,
    image: {
      src: home.seoMetadata.image.fixed.src,
      width: home.seoMetadata.image.fixed.width,
      height: home.seoMetadata.image.fixed.height,
    },
  }

  return (
    <Layout
      headerProps={{ navImage: home.mainNavigationImage }}
      metadata={metaObj}
    >
      <HomeScreen
        mainHeading={home.heroMainHeadingNode.childMarkdownRemark.html}
        scrollHeading={home.heroSmallScrollText}
        btnLink={home.heroButtonLink.slug}
        btnLabel={home.heroButtonLabel}
      />
      <About home={home} />
      <Strapline home={home} />
      <StraplineImage home={home} />
      <Collections home={home} collections={collections} />
      <Services home={home} />
      <Quotes
        bgImage={home.testimonialsSectionBackgroundImage}
        quotes={quotes}
      />
      <DownloadSection
        heading={home.magazineSectionHeading}
        text={home.magazineSectionTextNode.childMarkdownRemark.html}
        fileUrl={home.magazineSectionPdfFile.url}
        buttonText={home.magazineSectionButtonText}
      />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index

export const pageQuery = graphql`
  {
    datoCmsHomepage {
      magazineSectionPdfFile {
        url
      }
      magazineSectionHeading
      magazineSectionButtonText
      magazineSectionTextNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetadata {
        description
        title
        image {
          fixed(
            width: 1024
            height: 512
            imgixParams: {
              fit: "crop"
              w: "1024"
              h: "512"
              fm: "jpg"
              auto: "compress"
            }
          ) {
            width
            height
            src
          }
        }
      }
      heroMainHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      heroButtonLink {
        slug
      }
      heroButtonLabel
      heroSmallScrollText
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      aboutSectionSmallHeading
      aboutSectionMainHeading
      aboutSectionTextComponents {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      aboutSectionButtonLabel
      aboutSectionImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "4:7"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      servicesSectionSmallHeading
      servicesSectionMainHeading
      servicesSectionTextComponents {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      servicesSectionButtonLabel
      servicesSectionImage {
        fluid(
          maxWidth: 535
          imgixParams: {
            fit: "crop"
            ar: "4:6"
            fm: "jpg"
            auto: "compress"
            w: "535"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      straplineSectionStrapline
      straplineSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      straplineSectionForegroundImage {
        fixed(
          width: 300
          imgixParams: { fit: "crop", ar: "3:4", fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFixed
        }
      }
      testimonialsSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
    allDatoCmsCollection(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          collectionName
          slug
          heroSectionBackgroundImage {
            fluid(
              maxWidth: 800
              imgixParams: {
                fit: "crop"
                ar: "6:4"
                fm: "jpg"
                auto: "compress"
                w: "800"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    allDatoCmsTestimonial(
      sort: { order: DESC, fields: meta___publishedAt }
      limit: 5
    ) {
      edges {
        node {
          personName
          personOrganisation
          originalId
          testimonial
          personImage {
            fixed(
              width: 80
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                ar: "1:1"
                fit: "crop"
                sat: -100
                w: "80"
              }
            ) {
              src
            }
          }
        }
      }
    }
  }
`
