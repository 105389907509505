import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import breakpoints from 'helpers/breakpoints'

import Box from 'components/atoms/Box'

const ForeImgContainer = styled(Box)`
  position: relative;
  top: 60%;
  width: 300px;
  z-index: 20;
  height: 0;
  left: 10%;

  ${breakpoints.greaterThan('lg')`
        left: 14%;  
  `}

  ${breakpoints.greaterThan('xl')`
        left: 18%;  
  `}
`

const ForeImg = styled(Img)`
  width: 200px !important;
  height: 266px !important;
  position: relative;
  transform: translateY(-60%);

  ${breakpoints.greaterThan('lg')`
        width: 240px !important;
        height: 320px !important;
  `}
  ${breakpoints.greaterThan('xl')`
        width: 300px !important;
        height: 400px !important;
  `}
`

const StraplineImage = ({ home }) => {
  return (
    <ForeImgContainer>
      <ForeImg fixed={home.straplineSectionForegroundImage.fixed} />
    </ForeImgContainer>
  )
}

StraplineImage.propTypes = {
  home: PropTypes.object.isRequired,
}

export default memo(StraplineImage)
