import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Vignette from './Vignette'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.secondary};
  overflow: hidden;
`

const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const BackgroundVideo = ({ src }) => (
  <Container>
    <Video autoPlay muted loop>
      <source src={src} type="video/mp4" />
    </Video>
    <Vignette />
  </Container>
)
BackgroundVideo.propTypes = {
  src: PropTypes.string.isRequired,
}

export default memo(BackgroundVideo)
